.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.visible {
  visibility: visible
}

.collapse {
  visibility: collapse
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.right-0 {
  right: 0
}

.top-0 {
  top: 0
}

.z-50 {
  z-index: 50
}

.float-right {
  float: right
}

.m-1 {
  margin: 0.25rem
}

.m-16 {
  margin: 4rem
}

.m-2 {
  margin: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.-mt-8 {
  margin-top: -2rem
}

.mb-0 {
  margin-bottom: 0
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-1 {
  margin-left: 0.25rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-auto {
  margin-left: auto
}

.mr-2 {
  margin-right: 0.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-auto {
  margin-top: auto
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-0 {
  height: 0
}

.h-1 {
  height: 0.25rem
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-24 {
  height: 6rem
}

.h-32 {
  height: 8rem
}

.h-5 {
  height: 1.25rem
}

.h-64 {
  height: 16rem
}

.h-full {
  height: 100%
}

.max-h-\[700px\] {
  max-height: 700px
}

.min-h-\[30px\] {
  min-height: 30px
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/4 {
  width: 25%
}

.w-1\/5 {
  width: 20%
}

.w-12 {
  width: 3rem
}

.w-2\/4 {
  width: 50%
}

.w-3\/4 {
  width: 75%
}

.w-3\/5 {
  width: 60%
}

.w-32 {
  width: 8rem
}

.w-5 {
  width: 1.25rem
}

.w-\[500px\] {
  width: 500px
}

.w-full {
  width: 100%
}

.min-w-\[1000px\] {
  min-width: 1000px
}

.min-w-\[200px\] {
  min-width: 200px
}

.max-w-7xl {
  max-width: 80rem
}

.max-w-md {
  max-width: 28rem
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-items-center {
  justify-items: center
}

.gap-1 {
  gap: 0.25rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.gap-6 {
  gap: 1.5rem
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-ellipsis {
  text-overflow: ellipsis
}

.whitespace-nowrap {
  white-space: nowrap
}

.rounded-\[5px\] {
  border-radius: 5px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-tl-\[5px\] {
  border-top-left-radius: 5px
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem
}

.rounded-tr-\[5px\] {
  border-top-right-radius: 5px
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem
}

.border-0 {
  border-width: 0
}

.border-2 {
  border-width: 2px
}

.border-\[1px\] {
  border-width: 1px
}

.border-x-0 {
  border-left-width: 0;
  border-right-width: 0
}

.border-b-0 {
  border-bottom-width: 0
}

.border-l-0 {
  border-left-width: 0
}

.border-r-0 {
  border-right-width: 0
}

.border-t-0 {
  border-top-width: 0
}

.border-t-4 {
  border-top-width: 4px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-dotted {
  border-style: dotted
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 236 239 / var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(222 226 230 / var(--tw-border-opacity))
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(206 212 218 / var(--tw-border-opacity))
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(173 181 189 / var(--tw-border-opacity))
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(108 117 125 / var(--tw-border-opacity))
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(70 156 35 / var(--tw-border-opacity))
}

.border-orange {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 67 / var(--tw-border-opacity))
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(213 61 45 / var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity))
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 226 230 / var(--tw-bg-opacity))
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(173 181 189 / var(--tw-bg-opacity))
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 214 126 / var(--tw-bg-opacity))
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(70 156 35 / var(--tw-bg-opacity))
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 174 96 / var(--tw-bg-opacity))
}

.bg-inset {
  --tw-bg-opacity: 1;
  background-color: rgb(222 226 231 / var(--tw-bg-opacity))
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 67 / var(--tw-bg-opacity))
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 65 53 / var(--tw-bg-opacity))
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(213 61 45 / var(--tw-bg-opacity))
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 87 87 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 254 86 / var(--tw-bg-opacity))
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pl-12 {
  padding-left: 3rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pt-4 {
  padding-top: 1rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-middle {
  vertical-align: middle
}

.text-2xl {
  font-size: 1.5rem
}

.text-\[10px\] {
  font-size: 10px
}

.text-base {
  font-size: 1rem
}

.text-lg {
  font-size: 1.125rem
}

.text-sm {
  font-size: 0.875rem
}

.text-xl {
  font-size: 1.25rem
}

.text-xs {
  font-size: 0.75rem
}

.font-bold {
  font-weight: 700
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.font-thin {
  font-weight: 200
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.italic {
  font-style: italic
}

.leading-4 {
  line-height: 1rem
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(206 212 218 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(173 181 189 / var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(108 117 125 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(73 80 87 / var(--tw-text-opacity))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(52 58 64 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity))
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(213 61 45 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.opacity-0 {
  opacity: 0
}

.opacity-100 {
  opacity: 1
}

.opacity-50 {
  opacity: 0.5
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xs {
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline {
  outline-style: solid
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-opacity {
  transition-property: opacity
}

.transition-shadow {
  transition-property: box-shadow
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

.duration-300 {
  transition-duration: 300ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(222 226 230 / var(--tw-border-opacity))
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(108 117 125 / var(--tw-border-opacity))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 250 / var(--tw-bg-opacity))
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity))
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(222 226 230 / var(--tw-bg-opacity))
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(73 80 87 / var(--tw-text-opacity))
}

.hover\:opacity-100:hover {
  opacity: 1
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (min-width: 600px) {
  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute
  }

  .md\:m-2 {
    margin: 0.5rem
  }

  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem
  }

  .md\:mb-12 {
    margin-bottom: 3rem
  }

  .md\:mr-0 {
    margin-right: 0
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:flex-row {
    flex-direction: row
  }

  .md\:flex-col {
    flex-direction: column
  }

  .md\:flex-wrap {
    flex-wrap: wrap
  }

  .md\:items-end {
    align-items: flex-end
  }

  .md\:gap-0 {
    gap: 0
  }

  .md\:p-4 {
    padding: 1rem
  }

  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .md\:pt-4 {
    padding-top: 1rem
  }

  .md\:pt-6 {
    padding-top: 1.5rem
  }
}

@media (min-width: 1024px) {
  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:flex-row {
    flex-direction: row
  }
}

@media (min-width: 1440px) {
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(73 80 87 / var(--tw-border-opacity))
  }

  .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(73 80 87 / var(--tw-bg-opacity))
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(52 58 64 / var(--tw-bg-opacity))
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(206 212 218 / var(--tw-text-opacity))
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
  }
}