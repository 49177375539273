.cron_builder_bordering {
    border: 1px solid #ddd;
    border-top: none;
    text-align: center;
    padding: 10px;
    background: #fff;
}
.cron_builder_bordering input, .cron_builder_bordering select {
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding-left: 5px;
    cursor: pointer;
}
.df {
    display: flex;
}
.cron-builder-bg {
    background-color: #086090;
    color: white;
    text-align: center;
    margin-bottom: 4px;
    padding: 8px 0px;
}
.cron_builder_bordering select {
    background-color: white;
    width: 75px;
    cursor: pointer;
    padding: 4px 2px;
    border-radius: 4px;
}
.cron_builder_bordering select option:hover {
    background-color: #086090;
}
.well-small input {
    width: auto !important;
}
.cron_builder_bordering  input[type='radio'] {
    margin-top: 0px;
    vertical-align: middle;
}
.cron_builder {
    border: 1px solid #d0cbcb;
    padding: 5px;
    background-color: #dddef13d;
    width: 100%;
    max-width: 600px;
}
.text_align_left {
    text-align: left;
}
.cron_builder ul{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #dee2e6;
}
/* .cron-builder ul.nav-tabs{
    
} */
.cron_builder .nav li {
    cursor: pointer;
    flex: 0 0 85px;
    text-align: center;
}
.cron_builder .nav li a {
    color:#337ab7;

}
.cron_builder .nav-tabs .nav-link:focus, .cron_builder .nav-tabs .nav-link:hover {
    border-color: transparent transparent transparent;
    background-color: #eeeeee;
}
.cron_builder .nav-tabs .nav-item.show .nav-link, .cron_builder .nav-tabs .nav-link.active {
    border-color: #dee2e6 #dee2e6 #fff;
    background-color: #ffffff;
}
.cron_builder { 
    font-size: 14px;
}
.cron_builder .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width:767px) {
    .cron_builder .nav li {
        cursor: pointer;
        flex: 0 0 65px;
        text-align: center;
    }
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
div.tab-pane > div:nth-child(2) , div.tab-pane > div:nth-child(3), div.tab-pane > div:nth-child(4)  {
    display: none;
}